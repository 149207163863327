<script>
export default {
    name: 'FileTypeIcon',

    components: {
        /* File & Folder Defaults */
        'fti-file': () => import('@/assets/img/icons/vscode/default_file.svg'),
        'fti-folder': () => import('@/assets/img/icons/vscode/default_folder.svg'),
        'fti-folder-opened': () => import('@/assets/img/icons/vscode/default_folder_opened.svg'),
        /* End */
        'fti-conf': () => import('@/assets/img/icons/vscode/file_type_ini.svg'),
        'fti-css': () => import('@/assets/img/icons/vscode/file_type_css.svg'),
        'fti-html': () => import('@/assets/img/icons/vscode/file_type_html.svg'),
        'fti-hbs': () => import('@/assets/img/icons/vscode/file_type_handlebars.svg'),
        'fti-ini': () => import('@/assets/img/icons/vscode/file_type_ini.svg'),
        'fti-java': () => import('@/assets/img/icons/vscode/file_type_java.svg'),
        'fti-js': () => import('@/assets/img/icons/vscode/file_type_js.svg'),
        'fti-json': () => import('@/assets/img/icons/vscode/file_type_json.svg'),
        'fti-md': () => import('@/assets/img/icons/vscode/file_type_markdown.svg'),
        'fti-php': () => import('@/assets/img/icons/vscode/file_type_php.svg'),
        'fti-py': () => import('@/assets/img/icons/vscode/file_type_python.svg'),
        'fti-sbt': () => import('@/assets/img/icons/vscode/file_type_sbt.svg'),
        'fti-scala': () => import('@/assets/img/icons/vscode/file_type_scala.svg'),
        'fti-sh': () => import('@/assets/img/icons/vscode/file_type_shell.svg'),
        'fti-sql': () => import('@/assets/img/icons/vscode/file_type_sql.svg'),
        'fti-xml': () => import('@/assets/img/icons/vscode/file_type_xml.svg'),
        'fti-yml': () => import('@/assets/img/icons/vscode/file_type_yaml.svg'),
    },

    props: {
        name: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.componentKeys = Object.keys(this.$options.components);
    },

    render(createElement) {
        const constructComponentName = () => {
            const componentName = `fti-${this.name}`;
            return this.componentKeys.includes(componentName)
                ? componentName
                : 'fti-file';
        };

        return createElement('span', {
            class: `file-type-icon ${this.className || ''}`,
        }, [
            createElement(constructComponentName()),
        ]);
    },
};
</script>

<style lang="scss">
.file-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-type-icon > svg {
    height: 100%;
}
</style>
